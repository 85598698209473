<template>
  <div class="home">
    <Camera></Camera>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Camera from '@/components/Camera.vue'

export default {
  name: 'Home',
  components: {
    Camera
  }
}
</script>
